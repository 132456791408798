<div *ngIf="loading; else showTemplate">
  <p-progressSpinner></p-progressSpinner>
</div>

<ng-template #showTemplate>
  <div *ngIf="!webhooks; else showEmptyPage">
    <!-- Show an empty message -->
    <div class="flex flex-row min-w-full max-w-full">
      <div
        class="grid justify-content-center align-content-center align-items-center m-4 surface-card shadow-2 w-full"
      >
        <div class="col-12">
          <i class="pi pi-info-circle text-base text-gray-800"></i>
          <span class="font-semibold">
            No webhooks have been made for this account</span
          >
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showEmptyPage>
  <div class="flex flex-row max-w-full">
    <div
      class="flex flex-row flex-wrap m-4 w-full"
    >
      <div
        *ngFor="let webhook of webhooks; let i = index"
        class="border-1 border-orange-600 m-4 w-20rem h-20rem relative"
      >
        <div class="">
          <div class="m-4">
            <h2>{{ webhook.webhook_name }}</h2>
          </div>
          <div class="m-4">
            <span class="font-bold">{{ webhook.description }}</span>
          </div>
        </div>
        <div class="m-4 w-17rem absolute bottom-0">
          <button
            pButton
            type="submit"
            label="Upload"
            icon="pi pi-upload"
            class="primary w-full"
            (click)="openDialog(webhook)"
          ></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
