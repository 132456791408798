import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
  Form,
} from '@angular/forms';
import { SwitchWebhookService } from 'src/app/_services/switch-webhook.service';
import { UserService } from 'src/app/_services';
import { RoleService } from 'src/app/_services/role.service';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-switch-webhook',
  templateUrl: './switch-webhook.component.html',
  styleUrls: ['./switch-webhook.component.scss'],
})
export class SwitchWebhookComponent implements OnInit {
  switch_users: any[] = [];
  userRole: any;
  selectedUser: any;
  loading: boolean = false;
  webhook: any;
  webhooks: any[] = [];
  submitted: boolean = false;
  input_data: any;
  switch_form!: FormGroup;
  user_id: any;
  switch_fields: any[] = [];
  field_data: any[] = [];

  constructor(
    private fb: FormBuilder,
    private switchWebhookService: SwitchWebhookService,
    private userService: UserService,
    private roleService: RoleService,
    private messageService: MessageService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.userService.currentUser$.subscribe((user) => {
      this.userRole = user?.role?.name;
      this.user_id = user.id;
    });

    setTimeout(() => {
      this.switchWebhookService.getAdminSwitchCustomers(this.user_id).subscribe(
        (data: any) => {
          for (let users of data.body) {
            this.userService
              .getUserById(users.user_id)
              .subscribe((res: any) => {
                this.switch_users.push(res);
                console.log('Switch Users', this.switch_users);
                
              });
          }
        },
        (error) => {}
      );
    }, 2000);

    // this.switchWebhookService.getUserWebhooks().subscribe((data: any) => {
    //   this.switch_users = data.body;
    // });

    this.switchWebhookService.getFieldTypes().subscribe((data: any) => {
      this.switch_fields = data.body;
    });

    this.switch_form = this.fb.group({
      users_webhooks: this.fb.array([]),
      webhook_name: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      fields: this.fb.array([]),
    });

    this.addInputField();
    this.addUsersWebhooks();
  }

  get fieldArray() {
    return this.switch_form.get('fields') as FormArray;
  }

  get usersWebhooksArray() {
    return this.switch_form.get('users_webhooks') as FormArray;
  }

  newUsersWebhooks(): FormGroup {
    return this.fb.group({
      user_id: new FormControl('', Validators.required),
      webhook_url: new FormControl('', Validators.required),
      webhook: new FormControl(''),
    });
  }

  // Add a parent group with nested children form array
  newInputField(): FormGroup {
    return this.fb.group({
      field_name: ['', Validators.required],
      field_title: ['', Validators.required],
      field_data: this.fb.array([]), // Ensure this is a FormArray
    });
  }

  addUsersWebhooks() {
    // Get the last webhook_url value
    const lastWebhookUrl = this.usersWebhooksArray.length
      ? this.usersWebhooksArray.at(this.usersWebhooksArray.length - 1).get('webhook_url')?.value
      : '';
  
    // Add a new form group, prepopulating the webhook_url with the last value
    this.usersWebhooksArray.push(
      this.fb.group({
        user_id: new FormControl('', Validators.required),
        webhook_url: new FormControl(lastWebhookUrl), // Prepopulate with the last value
        webhook: new FormControl(''),
      })
    );
  }

  addInputField() {
    this.fieldArray.push(this.newInputField());
  }

  removeUsersWebhooks(index: number) {
    this.usersWebhooksArray.removeAt(index);
  }

  removeInputField(index: number) {
    this.fieldArray.removeAt(index);
  }

  inputFieldData(index: number): FormArray {
    return this.fieldArray.at(index).get('field_data') as FormArray;
  }

  newInputFieldData(): FormControl {
    return this.fb.control('', Validators.required);
  }

  addInputFieldData(index: number) {
    this.inputFieldData(index).push(this.newInputFieldData());
  }

  removeInputFieldData(fieldIndex: number, dataIndex: number) {
    this.inputFieldData(fieldIndex).removeAt(dataIndex);
  }

  onDropdownChange(e: any, index: number) {
    if (e.value === 'dropdown') {
      this.addInputFieldData(index);
    } else {
      this.inputFieldData(index).clear();
    }
  }

  dropdownChange(event: any, index: number) {
    const selectedUserId = this.usersWebhooksArray
      .at(index)
      .get('user_id')?.value;
    

    if (selectedUserId) {
      this.switchWebhookService
        .getBaseUrlById(selectedUserId)
        .subscribe((data: any) => {
          const baseUrl = data.body.base_url;
          this.usersWebhooksArray.at(index).patchValue({ webhook: baseUrl });
        });
    }
  }

  submitWebhook() {
    this.submitted = true;
    
    this.loading = true;
    if (this.switch_form.invalid) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Please fill in all fields',
      });
      this.loading = false;
      return;
    }

    

    
    // include the webhook inside the webhook form value
    for (let i = 0; i < this.usersWebhooksArray.length; i++) {
      this.usersWebhooksArray.at(i).patchValue({
        webhook: this.usersWebhooksArray.at(i).get('webhook')?.value + this.usersWebhooksArray.at(i).get('webhook_url')?.value,
      });
    }

    
    console.log('Switch Form Vaule', this.switch_form.value);
    this.switchWebhookService
      .createSwitchWebhook(this.switch_form.value)
      .subscribe(
        (data) => {},
        (error) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Webhook creation failed',
          });
          this.loading = false;
        },
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Webhook successfully created',
          });
          this.loading = false;
          this.switch_form.reset();
          this.submitted = false;
          this.webhook = '';
          // remove field_data from form
          this.fieldArray.clear();
          this.addInputField();

          // remove users_webhooks from form
          this.usersWebhooksArray.clear();
          this.addUsersWebhooks();

          //remove all input filed data
        }
      );
  }
}
