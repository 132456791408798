import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UserService } from 'src/app/_services/user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { confirmPasswordValidator } from 'src/app/validators/confirm-password.validator';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
})
export class UserDetailsComponent implements OnInit {
  loadingData: boolean = true;
  users: any;
  submitted: boolean = false;
  loading: boolean = false;

  passwordForm = this.fb.group({
    password: ['', Validators.required],
    confirm_password: ['', Validators.required],
  }, { validators: confirmPasswordValidator });

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    public userService: UserService,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    const user = this.config.data.user;

    this.userService.getAll().subscribe((users) => {
      this.loadingData = false;
      this.users = users.find((u) => u.id === user.id);
    });
  }

  get f() {
    return this.passwordForm.controls;
  }

  changeUserPassword() {
    this.submitted = true;
    if (this.passwordForm.invalid) {
      return;
    }
    this.loading = true;
    this.userService
      .resetPasswordByAdmin(this.users.id, this.passwordForm.value)
      .subscribe(
        (response) => {
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Password reset successfully',
            key: 'main',
          });
          this.loading = false;
          this.ref.close();
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'An error occurred: ' + error.error.message,
            key: 'main',
          });
        }
      );
  }
}
