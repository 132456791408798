<p-toast></p-toast>
<div
  class="align-content-center flex-wrap ml-4 mr-4 mt-4 shadow-3 surface-card"
>
  <div class="flex align-items-center justify-content-center">
    <h2 class="p-2 mt-0 mb-2 text-900 font-bold text-4xl">
      Switch Webhook Submit
    </h2>
  </div>
</div>
<div *ngIf="userRole == 'customer-switch'; else showSwitchForm">
  <app-switch-webhook-client></app-switch-webhook-client>
</div>
<ng-template #showSwitchForm>
  <form action="" [formGroup]="switch_form" (ngSubmit)="submitWebhook()">
    <div
      *ngIf="userRole != 'customer-switch'"
      class="flex justify-content-center align-content-center align-items-center max-w-full m-4"
    >
      <div
        class="flex justify-content-center align-content-center align-items-center surface-card w-full shadow-2 p-2"
      >
        <div class="grid flex flex-row flex-wrap w-full p-2">
          <div formArrayName="users_webhooks" class="col-12 flex flex-column">
            <div
              *ngFor="
                let userWebhooks of usersWebhooksArray.controls;
                index as i;
                last as isLast;
                first as isFirst
              "
              [formGroupName]="i"
              class="flex flex-row"
            >
              <div class="mr-4">
                <h3>Assign User</h3>
                <p-dropdown
                  [options]="switch_users"
                  optionLabel="username"
                  optionValue="id"
                  formControlName="user_id"
                  placeholder="Select a User"
                  (onChange)="dropdownChange($event, i)"
                  appendTo="body"
                ></p-dropdown>
                <div
                  *ngIf="
                    submitted &&
                    userWebhooks.get('user_id')?.invalid &&
                    (userWebhooks.get('user_id')?.touched ||
                      userWebhooks.get('user_id')?.pristine)
                  "
                >
                  <small class="text-red-500">A user is required</small>
                </div>
              </div>
              <div class="">
                <h3 for="">Endpoint</h3>
                <div class="flex flex-row w-full">
                  <div
                  *ngIf="userWebhooks.get('webhook')?.value"
                    class="justify-content-center align-content-center"
                  >
                    <span class="text-700 pr-2">{{ userWebhooks.get('webhook')?.value }}</span>
                  </div>
                  <div class="w-full">
                    <input
                      id="webhook_url"
                      formControlName="webhook_url"
                      pInputText
                      type="text"
                      
                      placeholder="Webhook URL"
                      class="w-full"
                    />
                    <div
                      *ngIf="
                        submitted &&
                        userWebhooks.get('webhook_url')?.invalid &&
                        (userWebhooks.get('webhook_url')?.touched ||
                          userWebhooks.get('webhook_url')?.pristine)
                      "
                    >
                      <small class="text-red-500">Webhook is required</small>
                    </div>
                  </div>
                  <div class="flex flex-row">
                    <div>
                      <button
                        *ngIf="isLast"
                        pButton
                        label=""
                        icon="pi pi-plus"
                        class="ml-2 p-button-success"
                        (click)="addUsersWebhooks()"
                      ></button>
                    </div>
                    <div>
                      <button
                        *ngIf="!isFirst"
                        pButton
                        label=""
                        icon="pi pi-minus"
                        class="ml-2 p-button-danger"
                        (click)="removeUsersWebhooks(i)"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <!-- <div class="col-6">
            <h3>Assign User</h3>
            <p-dropdown
              [options]="switch_users"
              [(ngModel)]="selectedUser"
              optionLabel="username"
              formControlName="user_id"
              placeholder="Select a User"
              (onChange)="dropdownChange($event)"
              appendTo="body"
            >
            </p-dropdown>
            <div
              *ngIf="
                submitted &&
                switch_form.get('user_id')?.invalid &&
                (switch_form.get('user_id')?.touched ||
                  switch_form.get('user_id')?.pristine)
              "
            >
              <small class="text-red-500">A user is required</small>
            </div>
          </div>
          <div class="col-6">
            <h3 for="">Endpoint</h3>
            <div class="flex flex-row w-full">
              <div
                *ngIf="webhook"
                class="justify-content-center align-content-center"
              >
                <span class="text-700 pr-2">{{ webhook }}</span>
              </div>
              <div class="w-full">
                <input
                  id="webhook_url"
                  formControlName="webhook_url"
                  pInputText
                  type="text"
                  [readOnly]="!webhook"
                  placeholder="Webhook URL"
                  class="w-full"
                />
                <div
                  *ngIf="
                    submitted &&
                    switch_form.get('webhook_url')?.invalid &&
                    (switch_form.get('webhook_url')?.touched ||
                      switch_form.get('webhook_url')?.pristine)
                  "
                >
                  <small class="text-red-500">Webhook is required</small>
                </div>
              </div>
            </div>
          </div> -->
          <div class="col-6">
            <h3>Submit Point Name</h3>
            <input
              id="webhook_name"
              formControlName="webhook_name"
              pInputText
              type="text"
              placeholder="Submit Point Name"
              class="w-full"
            />
            <div
              *ngIf="
                submitted &&
                switch_form.get('webhook_name')?.invalid &&
                (switch_form.get('webhook_name')?.touched ||
                  switch_form.get('webhook_name')?.pristine)
              "
            >
              <small class="text-red-500">Webhook name is required</small>
            </div>
          </div>
          <div class="col-12">
            <h3>Description</h3>
            <textarea
              placeholder="Description"
              formControlName="description"
              [rows]="5"
              [cols]="30"
              pInputTextarea
              [autoResize]="true"
              class="w-full"
            ></textarea>
            <div
              *ngIf="
                submitted &&
                switch_form.get('description')?.invalid &&
                (switch_form.get('description')?.touched ||
                  switch_form.get('description')?.pristine)
              "
            >
              <small class="text-red-500">description is required</small>
            </div>
          </div>
          <div class="col-3" formArrayName="fields">
            <div>
              <h3>Add Custom Fields</h3>
            </div>
            <div
              *ngFor="
                let fields of fieldArray.controls;
                index as i;
                last as isLast;
                first as isFirst
              "
              [formGroupName]="i"
              class="flex flex-row mb-4"
            >
              <div class="flex flex-row">
                <div class="flex flex-column">
                  <div>
                    <h3>Field Name:</h3>
                    <input
                      pInputText
                      formControlName="field_title"
                      class="mb-4"
                      placeholder="Field Name"
                    />
                  </div>
                  <div>
                    <div class="flex flex-row">
                      <div>
                        <p-dropdown
                          [options]="switch_fields"
                          optionLabel="field_name"
                          optionValue="field_name"
                          formControlName="field_name"
                          placeholder="Select a Field"
                          class="w-full"
                          appendTo="body"
                          (onChange)="onDropdownChange($event, i)"
                        ></p-dropdown>
                      </div>
                      <div class="flex flex-row">
                        <div>
                          <button
                            *ngIf="isLast"
                            pButton
                            label=""
                            icon="pi pi-plus"
                            class="ml-2 p-button-success"
                            (click)="addInputField()"
                          ></button>
                        </div>
                        <div>
                          <button
                            *ngIf="!isFirst"
                            pButton
                            label=""
                            icon="pi pi-minus"
                            class="ml-2 p-button-danger"
                            (click)="removeInputField(i)"
                          ></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div formArrayName="field_data">
                <div
                  class="flex flex-row mt-4"
                  *ngFor="
                    let data of inputFieldData(i).controls;
                    index as j;
                    last as isLast;
                    first as isFirst
                  "
                >
                  <div>
                    <input
                      pInputText
                      [formControlName]="j"
                      type="text"
                      placeholder="dropdown value"
                      class="ml-2"
                    />
                  </div>
                  <div>
                    <button
                      *ngIf="isLast"
                      pButton
                      label=""
                      icon="pi pi-plus"
                      class="ml-2 p-button-success"
                      (click)="addInputFieldData(i)"
                    ></button>
                  </div>
                  <div>
                    <button
                      *ngIf="!isFirst"
                      pButton
                      label=""
                      icon="pi pi-minus"
                      class="ml-2 p-button-danger"
                      (click)="removeInputFieldData(i, j)"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <button
              pButton
              type="submit"
              label="Add Submit Point"
              icon="pi pi-plus"
              class="mt-4 mb-4"
              [loading]="loading"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </form>
</ng-template>
