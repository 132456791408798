
<p-progressSpinner
  *ngIf="loadingData"
  styleClass="w-4rem h-4rem"
  strokeWidth="8"
  fill="var(--surface-ground)"
  animationDuration=".5s"
></p-progressSpinner>
<div *ngIf="users">
  <p><strong>User ID:</strong> {{ users.id }}</p>
  <p><strong>Username:</strong> {{ users.username }}</p>
  <p><strong>First Name:</strong> {{ users.first_name }}</p>
  <p><strong>Last Name:</strong> {{ users.last_name }}</p>
  <p><strong>Email:</strong> {{ users.email }}</p>
</div>
<div>
  <div>
    <h3>Change Password</h3>
  </div>
  <div>
    <form [formGroup]="passwordForm" (ngSubmit)="changeUserPassword()">
      <div class="flex flex-row mb-4">
        <div class="flex flex-column mr-4">
          <div>
            <label for="newPassword">New Password</label>
          </div>
          <div>
            <input pInputText type="password" formControlName="password" />
          </div>
          <div
            *ngIf="
              submitted && this.userService.userForm.get('password')?.errors
            "
            class="invalid-feedback"
          >
            <p-message
              *ngIf="
                f &&
                f['password'] &&
                f['password'].errors &&
                f['password'].errors['required']
              "
              severity="error"
              text="* Please enter your password"
              styleClass="mt-2 w-full"
            ></p-message>
          </div>
        </div>
        <div>
          <div class="flex flex-column">
            <div>
              <label for="confirmPassword">Confirm Password</label>
            </div>
            <div>
              <input
                pInputText
                type="password"
                formControlName="confirm_password"
              />
            </div>
            <div
              *ngIf="
                submitted && this.userService.userForm.get('password')?.errors
              "
              class="invalid-feedback"
            >
              <p-message
                *ngIf="
                  f &&
                  f['confirm_password'] &&
                  f['confirm_password'].errors &&
                  f['confirm_password'].errors['required']
                "
                severity="error"
                text="* Please enter your password"
                styleClass="mt-2 w-full"
              ></p-message>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      <div class="mb-4">
        <p-message
          *ngIf="this.passwordForm.errors?.['PasswordNoMatch']"
          severity="error"
          text="The entered passwords do not match"
        ></p-message>

        <p-message
          *ngIf="
          (f['confirm_password'].value ===
          f['password'].value) && 
          !this.userService.userForm.errors?.['PasswordNoMatch'] && 
          f['confirm_password'].value != '' && 
          f['password'].value != '' && 
          this.userService.userForm.get('confirm_password')?.touched
          "
          severity="success"
          text="The passwords match!"
        ></p-message>
      </div>
      <button pButton pRipple type="submit" [loading]="loading">Change Password</button>
    </form>
  </div>
</div>
